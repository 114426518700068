<template>
  <div class="message-list mt-4">
    <div v-for="(message, k) in messages" :key="message.id" :id="'message-' + message.id">
      <div v-if="!isNotificationType(message.meta.type)">
        <div
          class="d-flex align-center justify-center mb-4 text-body-1 secondary-font grey--text text--darken-2"
          v-if="showDate(message.createdAt, k)"
        >{{ $moment(message.createdAt).format('dddd DD MMM YYYY') }}</div>
        <div
          class="d-flex flex-row align-center mb-4"
          :class="{'flex-row-reverse mr-5': message.direction === 'OUT'}"
        >
          <div :class="{'mr-2': message.direction === 'IN', 'ml-2': message.direction === 'OUT'}">
            <message-list-avatar :type="conversation.channel.type" color="primary" :author="message.authorId"></message-list-avatar>
          </div>
          <div
            :class="{'message-list-content-50': $vuetify.breakpoint.mdAndUp, 'message-list-content-85': $vuetify.breakpoint.smAndDown}"
          >
            <message-list-content
              :content="message.content"
              :direction="message.direction"
              :meta="message.meta"
              :loading="message.loading"
              :status="message.status"
              :created-at="message.createdAt" 
              :error="message.error"
            ></message-list-content>
          </div>
        </div>
      </div>
      <div v-else class="d-flex align-center justify-center mb-4">
        <message-list-notification :meta="message.meta" :created-at="message.createdAt" :author-id="message.authorId"></message-list-notification>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.message-list {
  &-content {
    &-50 {
      max-width: 50%;
    }
    &-85 {
      max-width: 85%;
    }
  }
}
</style>

<script>
import MessageListNotification from "./message-list-notification.vue";
import MessageListContent from "./message-list-content.vue";
import MessageListAvatar from "./message-list-avatar.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    messages: {
      type: Array,
      default: function() {
        return [];
      }
    },
    conversation: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  methods: {
    isNotificationType(type) {
      return this.notficationTypes.includes(type);
    },
    showDate(createdAt, index) {
      if (index > 0) {
        return !this.$moment(createdAt).isSame(
          this.$moment(this.messages[index - 1].createdAt),
          "day"
        );
      }
      return true;
    }
  },
  components: {
    MessageListAvatar,
    MessageListContent,
    MessageListNotification
  },
  computed: {
    ...mapGetters({ notficationTypes: "message/notificationTypes" })
  }
};
</script>