<template>
  <span>
    <template v-if="loading">
      <v-progress-circular class="mr-2" indeterminate size="10" width="1" :color="color"></v-progress-circular>
    </template>
    <template v-else>
      <span class="ml-2" v-if="status">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="color"
              x-small
              v-if="status === 'FAILED' || status === 'REJECTED'"
              dark
              v-bind="attrs"
              v-on="on"
            >mdi-alert-circle-outline</v-icon>
            <v-icon
              :color="color"
              x-small
              v-else-if="status === 'SENT'"
              dark
              v-bind="attrs"
              v-on="on"
            >mdi-check</v-icon>
            <v-icon
              :color="color"
              x-small
              v-else-if="status === 'READ' || status === 'DELIVERED'"
              dark
              v-bind="attrs"
              v-on="on"
            >mdi-check-all</v-icon>
            <span v-else></span>
          </template>
          <span>{{ message }}</span>
        </v-tooltip>
      </span>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "white"
    },
    error: {
      type: String,
      default: null
    }
  },
  data: () => ({}),
  computed: {
    message() {
      const self = this;
      let message;
      switch (this.status) {
        case "FAILED":
          message = self.error;
          break;
        case "SENT":
          message = self.$t('chat.messages.status.SENT')
          break;
        case "EXPIRED":
          message = self.$t('chat.messages.status.EXPIRED')
          break;
        case "DELIVERED":
          message = self.$t('chat.messages.status.DELIVERED')
          break;
        case "READ":
          message = self.$t('chat.messages.status.READ')
          break;
        case "REJECTED":
          message = self.$t('chat.messages.status.REJECTED')
          break;
      }
      return message;
    }
  }
};
</script>