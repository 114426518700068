<template>
  <v-navigation-drawer
    v-show="drawer.right"
    width="320"
    hide-overlay
    clipped
    right
    app
    v-model="drawer.right"
    class="elevation-1 app-nav"
  >
    <v-list-item class="mt-1">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">
          <span class="secondary-font">{{ $i18n.t("chat.conversation.infos.title") }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span>{{ $i18n.t("chat.conversation.infos.subtitle") }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-tabs v-model="tab" background-color="grey lighten-5" fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab href="#contact" v-on="on" v-bind="attrs">
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-tab>
        </template>
        <span>{{ $t("chat.conversation.infos.tabs.contact.title") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-tab :disabled="!isContactInfo" href="#conversations" v-on="on" v-bind="attrs">
            <v-icon>mdi-chat-outline</v-icon>
          </v-tab>
        </template>
        <span>{{ $t("chat.conversation.infos.tabs.conversation.title") }}</span>
      </v-tooltip>
    </v-tabs>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      :style="{ height: windowHeight - 30 + 'px' }"
      ref="infos-wrapper"
    >
      <v-tabs-items v-model="tab">
        <v-tab-item value="contact">
          <v-card flat>
            <v-card-text v-if="!loading.getContact">
              <contact-info
                :service-id="serviceId"
                :identifier.sync="identifier"
                :contact-info="contactInfo"
                @updateContactInfo="updateContactInfo"
                @updateIdentifier="updateIdentifier"
                @contactInfoSaved="contactInfoSaved"
                :reload-id="reloadContactInfoId"
              ></contact-info>
            </v-card-text>
            <v-card-text v-else>
              <div class="text-center mt-5">
                <v-progress-circular indeterminate color="secondary" width="4" size="40"></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="conversations">
          <v-card flat>
            <v-card-text v-if="!loading.getConversation">
              <div>
                <conversation-list
                  :conversations="conversations"
                  :conversation="conversation"
                  @selectConversation="selectConversation"
                ></conversation-list>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <div class="text-center mt-5">
                <v-progress-circular indeterminate color="secondary" width="4" size="40"></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>


<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapActions } from "vuex";
import ConversationList from "./conversation-list";
import ContactInfo from "./contact-info";
export default {
  props: {
    drawer: {
      type: Object,
      default: function() {
        return {
          right: true
        };
      }
    },
    windowHeight: {
      type: Number
    },
    contactInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    conversationId: {
      type: Number
    },
    serviceId: {
      type: Number
    }
  },
  data: () => ({
    tab: null,
    reload: true,
    loading: {
      saveContactInfoIdentifier: false,
      getContact: false,
      getConversation: false
    },
    conversations: [],
    conversation: null,
    identifier: null,
    reloadContactInfoId: +new Date()
  }),
  sockets: {
    "conversations/set-contact-info"(data) {
      if (data.status === "success") {
        this.$emit("updateContactInfo", {
          conversationId: data.conversation.id,
          contactInfo: data.contactInfo
        });
        // select tab contact
        this.tab = "contact";
      }
    },
    "contacts-infos/get"(data) {
      this.loading.getContact = false;
      if (data.status === "success") {
        this.$emit("updateConversationContactInfo", {
          conversationId: this.conversationId,
          contactInfo: data.contactInfo
        });
      } else if (data.status === "error") {
        const code = data.code;
        let message;
        if (code === "E403") {
          message = "error_codes.403";
        } else if (code === "E404") {
          message = "contacts.errors.E404";
        } else {
          message = "error_occured";
        }
        this.notify({ message: this.$i18n.t(message) });
      }
    }
  },
  watch: {
    conversationId: {
      immediate: true,
      handler() {
        this.reloadContactInfoId = +new Date();
      }
    },
    "contactInfo.identifier": {
      handler(identifier) {
        if (!identifier) {
          this.tab = "contact";
          this.identifier = null;
        } else {
          this.identifier = identifier;
          // get conversations for this contact info id
          this.getConversationsByContactInfoId(this.contactInfo.id);
        }
      },
      immediate: true
    },
  },
  methods: {
    updateIdentifier(identifier) {
      this.$emit('updateIdentifier', identifier);
    },
    updateContactInfo(contactInfo) {
      this.$emit("updateContactInfo", {
        conversationId: this.conversationId,
        contactInfo: contactInfo
      });
    },
    contactInfoSaved() {
      this.$socket.emit("conversations/set-contact-info", {
        conversationId: this.conversationId,
        contactInfoIdentifier: this.identifier
      });
    },
    async getConversationsByContactInfoId(id) {
      if (!id) return;
      this.loading.getConversation = true;
      this.conversations = [];
      try {
        const response = await this.request({
          url: `messages.last?filter=conversation.contactInfoId||$eq||${id}&limit=20`,
          messages: {
            403: true
          }
        });
        this.setConversations(response.data.data);
      } catch (error) {
        //empty
      }
      this.loading.getConversation = false;
    },
    setConversations(datas) {
      const conversations = [];
      for (const data of datas) {
        const { conversation, ...message } = data;
        conversations.push({ ...conversation, message: message });
      }
      this.conversations = conversations;
    },
    selectConversation(conversation) {
      this.reload = false;
      this.conversation = conversation;
      this.$emit("selectConversation", conversation);
    },
    ...mapActions({ request: "request", notify: "notification/notify" })
  },
  computed: {
    isContactInfo() {
      return this.$utils.hasProperty(this.contactInfo, "identifier");
    }
  },
  components: {
    PerfectScrollbar,
    ConversationList,
    ContactInfo
  }
};
</script>