<template>
  <div>
    <v-list nav class="pl-0 pr-0">
      <v-list-item
        v-for="conv in conversations"
        :key="conv.id"
        @click="selectConversation(conv)"
        ripple
        :input-value="conversation && conv.id === conversation.id"
        active-class="bg-color lighten-5"
      >
        <conversation-list-avatar :type="conv.channel.type"></conversation-list-avatar>

        <v-list-item-content class="pr-4">
          <v-list-item-title class="text-subtitle-2">
            <span class="font-weight-bold">{{ conv.sourceId }}</span>
          </v-list-item-title>
          <v-list-item-subtitle class="grey--text text--darken-3">{{ conv.channel.service.name }}</v-list-item-subtitle>
          <v-list-item-subtitle>
            <conversation-list-content
              :meta="conv.message.meta"
              :content="conv.message.content"
              :authorId="conv.message.authorId"
            ></conversation-list-content>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span
              class="grey--text text-body-2 text--darken-1"
            >{{ $moment(conv.message.createdAt).format('YYYY-MM-DD HH:mm') }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="conv.totalMessageUnread > 0">
          <v-badge color="red" :content="conv.totalMessageUnread"></v-badge>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import ConversationListAvatar from "./conversation-list-avatar";
import ConversationListContent from "./conversation-list-content";

export default {
  props: {
    conversations: {
      type: Array,
      default: function() {
        return [];
      }
    },
    conversation: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },
  data: () => ({}),
  methods: {
    selectConversation(conversation) {
      this.$emit("selectConversation", conversation);
    }
  },
  components: {
    ConversationListAvatar,
    ConversationListContent
  }
};
</script>