var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-list-content"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"rounded-lg pa-2 elevation-1 text-body-1",class:{
      'grey lighten-3': _vm.direction === 'IN',
      'blue white--text': _vm.direction === 'OUT',
    }},[(_vm.meta.type === 'IMAGE')?[_c('div',{staticClass:"image d-flex align-center flex-column"},[(hover)?_c('div',{staticClass:"actions"},[_c('a',{staticClass:"text-right action",attrs:{"href":_vm.content,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")])],1)]):_vm._e(),_c('v-img',{attrs:{"cover":"","height":"150","src":_vm.content,"width":"200","lazy-src":"/img/load-image.jpg"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)]:(_vm.meta.type === 'VIDEO')?[_c('video',{attrs:{"width":"200","preload":"none","controls":"","height":"150"}},[_c('source',{attrs:{"src":_vm.content,"type":"video/mp4"}}),_c('source',{attrs:{"src":_vm.content,"type":"video/ogg"}}),_c('source',{attrs:{"src":_vm.content,"type":"video/webm"}})])]:(_vm.meta.type === 'AUDIO')?[_c('audio',{attrs:{"controls":"","preload":"none"}},[_c('source',{attrs:{"src":_vm.content}})])]:(_vm.meta.type === 'FILE')?[_c('div',{staticClass:"d-flex align-center flex-column file"},[(hover)?_c('div',{staticClass:"actions"},[_c('a',{staticClass:"text-right action",attrs:{"href":_vm.content,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")])],1)]):_vm._e(),_c('v-icon',{staticClass:"icon",attrs:{"dark":"","color":"primary"}},[_vm._v("mdi-file-document-outline")]),(_vm.meta.caption)?_c('div',{staticClass:"text-center font-weight-bold mb-1"},[_vm._v(_vm._s(_vm.meta.caption))]):_vm._e()],1)]:[_c('div',{class:("content-" + _vm.direction),domProps:{"innerHTML":_vm._s(_vm.getContent(_vm.content))}})],_c('div',{staticClass:"text-right text-body-2",class:{
        'grey lighten-3': _vm.direction === 'IN',
        'grey--text text--lighten-4': _vm.direction === 'OUT',
      }},[_vm._v(" "+_vm._s(_vm.$moment(_vm.createdAt).format("HH:mm"))+" "),(_vm.direction === 'OUT')?_c('message-list-content-status',{attrs:{"loading":_vm.loading,"error":_vm.error,"status":_vm.status}}):_vm._e()],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }