<template>
  <div class="social-network">
    <template v-if="author">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar :color="color" size="45" v-on="on" v-bind="attrs">
            <span class="white--text title">{{ getAuthorAvatarTitle(author) }}</span>
          </v-avatar>
        </template>
        <span>{{ author }}</span>
      </v-tooltip>
    </template>
    <v-avatar size="45" v-else :class="avatar.icon.class">
      <v-icon dark>{{ avatar.icon.value }}</v-icon>
    </v-avatar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    type: {
      required: true,
      type: String
    },
    author: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  data: () => ({}),
  methods: {
    getAuthorAvatarTitle(name) {
      return this.$utils.getAvatarTitle(name);
    }
  },
  computed: {
    ...mapGetters({ avatars: "conversation/avatar" }),
    avatar() {
      if (this.avatars[this.type]) {
        return this.avatars[this.type];
      } else {
        return this.avatars["default"];
      }
    }
  }
};
</script>