<template>
  <div class="message-list-content">
    <v-hover v-slot="{hover}">
      <div
        class="rounded-lg pa-2 elevation-1 text-body-1"
        :class="{
        'grey lighten-3': direction === 'IN',
        'blue white--text': direction === 'OUT',
      }"
      >
        <template v-if="meta.type === 'IMAGE'">
          <div class="image d-flex align-center flex-column">
            <div class="actions" v-if="hover">
              <a :href="content" target="_blank" class="text-right action">
                <v-icon color="primary">mdi-eye-outline</v-icon>
              </a>
            </div>
            <v-img cover height="150" :src="content" width="200" lazy-src="/img/load-image.jpg">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </template>
        <template v-else-if="meta.type === 'VIDEO'">
          <video width="200" preload="none" controls height="150">
            <source :src="content" type="video/mp4" />
            <source :src="content" type="video/ogg" />
            <source :src="content" type="video/webm" />
          </video>
        </template>
        <template v-else-if="meta.type === 'AUDIO'">
          <audio controls preload="none">
            <source :src="content" />
          </audio>
        </template>
        <template v-else-if="meta.type === 'FILE'">
          <div class="d-flex align-center flex-column file">
            <div class="actions" v-if="hover">
              <a :href="content" target="_blank" class="text-right action">
                <v-icon color="primary">mdi-eye-outline</v-icon>
              </a>
            </div>
            <v-icon class="icon" dark color="primary">mdi-file-document-outline</v-icon>
            <div class="text-center font-weight-bold mb-1" v-if="meta.caption">{{ meta.caption }}</div>
          </div>
        </template>
        <template v-else>
          <div :class="`content-${direction}`" v-html="getContent(content)"></div>
        </template>
        <div
          :class="{
          'grey lighten-3': direction === 'IN',
          'grey--text text--lighten-4': direction === 'OUT',
        }"
          class="text-right text-body-2"
        >
          {{ $moment(createdAt).format("HH:mm") }}
          <message-list-content-status
            v-if="direction === 'OUT'"
            :loading="loading"
            :error="error"
            :status="status"
          ></message-list-content-status>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<style lang="scss">
.message-list-content {
  position: relative;
  .caption-content {
    width: 200px;
  }
  .content-OUT {
    a {
      color: #ffffff;
    }
  }
  .actions {
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    text-align: right;
    z-index: 999;
    padding-right: 5%;
    padding-left: 5%;
    a {
      text-decoration: none;
    }
  }
  .file {
    .icon {
      font-size: 110px;
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import MessageListContentStatus from "./message-list-content-status";
export default {
  props: {
    meta: {
      type: Object,
      default: function() {
        return {
          type: "TEXT",
          caption: null
        };
      }
    },
    content: {
      type: String,
      default: ""
    },
    direction: {
      type: String,
      default: "IN"
    },
    loading: {
      type: Boolean,
      default: null
    },
    status: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    createdAt: {
      type: String,
      default: null
    }
  },
  data: () => ({
    autoLinkOptions: {
      mention: "facebook",
      hashtag: "facebook",
      email: true,
      phone: true,
      truncate: {
        length: 100,
        location: "smart"
      }
    }
  }),
  methods: {
    ...mapActions({ request: "request" }),
    getContent() {
      return this.$sanitize(this.$autolinker.link(this.content));
    }
  },
  components: {
    MessageListContentStatus
  }
};
</script>