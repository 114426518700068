<template>
  <v-navigation-drawer
    width="300"
    clipped
    v-model="drawer.left"
    hide-overlay
    app
    class="elevation-1 app-nav"
  >
    <v-list-item class="mt-1">
      <v-list-item-content>
        <v-list-item-title>
          <span class="font-weight-medium secondary-font">{{ $i18n.t("chat.title") }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span>{{ $i18n.t("chat.conversation.subtitle") }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action @click="() => {}">
        <v-btn icon @click="displayFilterConversation()">
          <v-icon color="grey lighten-1">mdi-filter-variant</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
    <div :style="{ height: windowHeight - 60 + 'px' }">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        :style="{ height: windowHeight + 20 + 'px' }"
        ref="conversations-wrapper"
        class="infinite-conversation-wrapper"
      >
        <conversation-list
          :conversations="conversations"
          :conversation="conversation"
          @selectConversation="selectConversation"
        ></conversation-list>
        <infinite-loading
          force-use-infinite-wrapper=".infinite-conversation-wrapper"
          :distance="10"
          @infinite="getMoreConversations"
          :identifier="paginations.infiniteId"
          direction="bottom"
        >
          <v-progress-circular
            indeterminate
            color="secondary"
            width="3"
            size="40"
            slot="spinner"
            class="mt-4"
          ></v-progress-circular>
          <span slot="no-more"></span>
          <span slot="no-results"></span>
          <div slot="error" slot-scope="{ trigger }">
            <div>{{ $i18n.t("error_occured") }}</div>
            <div>
              <v-btn depressed @click="trigger">{{ $i18n.t("btn.retry") }}</v-btn>
            </div>
          </div>
        </infinite-loading>
      </perfect-scrollbar>
    </div>
    <!-- filter conversation form -->
    <filter-conversation
      :dialog="dialogFilterConversations"
      :configuration="filterConversationsOptions"
      @filter="filterConversationsApply"
    ></filter-conversation>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import FilterConversation from "./../commons/filter";
import ConversationList from "./conversation-list";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import InfiniteLoading from "vue-infinite-loading";
export default {
  created() {
    this.setFilterConversationsOptions();
  },
  props: {
    drawer: {
      type: Object,
      default: function() {
        return {
          left: true
        };
      }
    },
    windowHeight: {
      type: Number
    },
    conversations: {
      type: Array,
      default: function() {
        return [];
      }
    },
    conversation: {
      type: Object,
      default: function() {
        return {};
      }
    },
    paginations: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: () => ({
    filterConversationsOptions: [],
    dialogFilterConversations: {
      display: false,
      title: null
    }
  }),
  methods: {
    getMoreConversations($state) {
      this.$emit("getMoreConversations", $state);
    },
    selectConversation(conversation) {
      this.$emit("selectConversation", conversation);
    },
    displayFilterConversation() {
      this.dialogFilterConversations = {
        title: this.$i18n.t("chat.conversation.filter.title"),
        display: true
      };
    },
    filterConversationsApply(form) {
      this.$emit("filterConversationsApply", form);
    },
    setFilterConversationsOptions() {
      const self = this;
      this.filterConversationsOptions = [
        {
          name: "conversation.contactInfo.identifier",
          type: {
            name: "VTextField",
            operator: "$eq",
            operators: [
              { value: "$eq", text: this.$i18n.t("form.fields.operator.eq") },
              {
                value: "$contL",
                text: this.$i18n.t("form.fields.operator.contains")
              }
            ],
            options: {
              label: this.$i18n.t("conversations.fields.contact_identifier.title"),
              placeholder: this.$i18n.t(
                "conversations.fields.contact_identifier.title"
              ),
              clearable: true
            }
          }
        },
        {
          name: "conversation.sourceId",
          type: {
            name: "VTextField",
            operator: "$eq",
            operators: [
              { value: "$eq", text: this.$i18n.t("form.fields.operator.eq") },
              {
                value: "$contL",
                text: this.$i18n.t("form.fields.operator.contains")
              }
            ],
            options: {
              label: this.$i18n.t("conversations.fields.source_id.title"),
              placeholder: this.$i18n.t(
                "conversations.fields.source_id.title"
              ),
              clearable: true
            }
          }
        },
        {
          name: "conversation.channel.identifier",
          type: {
            name: "VSelect",
            operator: "$eq",
            options: {
              items: self.channelItems,
              label: self.$i18n.t(
                "conversations.fields.channel_identifier.title"
              ),
              clearable: true
            }
          }
        },
        {
          name: "conversation.totalMessageUnread",
          type: {
            name: "VCheckbox",
            parser: value => {
              if (value) {
                return {
                  field: "conversation.totalMessageUnread",
                  operator: "$ne",
                  value: 0
                };
              } else {
                return null;
              }
            },
            options: {
              label: this.$i18n.t(
                "conversations.fields.message_unreaded.title"
              ),
              color: "primary"
            }
          }
        }
      ];
    },
  },
  computed: {
    ...mapGetters({ channelItems: "channel/channelItems" })
  },
  components: {
    ConversationList,
    PerfectScrollbar,
    InfiniteLoading,
    FilterConversation
  }
};
</script>