<template>
  <span
    class="grey--text text-body-2 text--darken-3 secondary-font"
  >{{ $i18n.t('chat.messages.notification.' + meta.type, { user: $utils.capitalize(authorId), date: $moment(createdAt).format('HH:mm') }) }}</span>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: true
    },
    authorId: {
      type: String
    },
    createdAt: {
      type: String
    }
  },
  data: () => ({})
};
</script>