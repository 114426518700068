<template>
  <div>
    <div v-if="socketIsAuthenticated && !loading">
      <chat></chat>
      <footer-bar class="justify-center"></footer-bar>
    </div>
    <div class="d-flex justify-center align-center" style="height: 300px" v-else>
      <v-progress-circular indeterminate color="secondary" width="4" size="60"></v-progress-circular>
    </div>
  </div>
</template>


<script>
import FooterBar from "./../../components/layout/footer-bar";
import { mapGetters } from "vuex";
import Chat from "./../../components/chat/chat.vue";
import { mapActions } from "vuex";

export default {
  mounted() {
    // connect to socket
    if (!this.socketIsConnected) {
      this.$socket.open();
    }
  },
  data: () => ({
    drawer: {
      left: true,
      right: true
    },
    loading: true
  }),
  methods: {
    async getChannels() {
      try {
        const response = await this.request({ url: "channels.items" });
        this.setChannelItems(response.data);
      } catch (error) {
        // empty
      }
      return;
    },
    ...mapActions({
      request: "request",
      setChannelItems: "channel/setChannelItems"
    })
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      try {
        await vm.$store.dispatch("settings/setChannels");
        await vm.getChannels();
        vm.loading = false;
      } catch (error) {
        // empty
      }
    });
  },
  metaInfo() {
    return {
      title: this.$i18n.t("chat.title")
    };
  },
  components: {
    Chat,
    FooterBar
  },
  computed: {
    ...mapGetters({
      socketIsConnected: "socketIsConnected",
      socketIsAuthenticated: "socketIsAuthenticated"
    })
  }
};
</script>