<template>
  <div>
    <v-snackbar
      light
      timeout="-1"
      class="conversation-notification"
      v-model="snackbar"
      top
      right
    >
      <template v-slot:default>
        <v-card flat max-width="300" class="px-0 py-0" v-if="snackbar">
          <v-list three-line class="px-0 py-0">
            <v-list-item class="px-0">
              <v-list-item-avatar class="social-network mr-3">
                <v-icon v-if="avatar.icon" dark :class="avatar.icon.class">{{
                  avatar.icon.value
                }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="align-self-start">
                <v-list-item-title>
                  <span class="font-weight-bold">
                    {{ notification.contactId }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-0 grey--text text--darken-2 font-weight-medium">
                  {{ notification.channel.service.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span v-if="notification.meta.type === 'IMAGE'">
                    {{ $t("chat.messages.type.image") }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span
                    v-if="notification.meta.type"
                    v-html="getContent(notification.content)"
                  >
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn depressed @click="closeAllNotification()">{{
              $i18n.t("btn.close_all")
            }}</v-btn>
            <v-btn
              depressed
              @click="closeNotification(notification.conversationId)"
              >{{ $i18n.t("btn.close") }}</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="openNotification(notification.conversationId)"
              >{{ $i18n.t("btn.open") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
      <template v-slot:action>
        <v-badge
          :content="counter"
          value="true"
          color="blue"
          overlap
          class="mr-2"
        >
          <v-icon>mdi-chat-outline</v-icon>
        </v-badge>
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss">
.conversation-notification {
  .v-snack__wrapper {
    animation: MoveUpDown 2s ease infinite;
    position: absolute;
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    notifications: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({}),
  methods: {
    openNotification(conversationId) {
      this.$emit("openNotification", conversationId);
    },
    closeNotification(conversationId) {
      this.$emit("closeNotification", conversationId);
    },
    closeAllNotification() {
      this.$emit("closeAllNotification");
    },
    getContent(content) {
      return this.$sanitize(this.$autolinker.link(content));
    },
  },
  computed: {
    ...mapGetters({ avatars: "conversation/avatar" }),
    notification() {
      const len = this.notifications.length;
      if (len) {
        return this.notifications[len - 1];
      }
      return null;
    },
    snackbar() {
      const len = this.notifications.length;
      return len ? true : false;
    },
    avatar() {
      if (this.notification) {
        if (this.avatars[this.notification.channel.type]) {
          return this.avatars[this.notification.channel.type];
        } else {
          return this.avatars["default"];
        }
      }
      return {};
    },
    counter() {
      return this.notifications.length;
    },
  },
};
</script>