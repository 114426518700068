<template>
  <v-card-text :style="{ height: windowHeight - 70 + 'px' }">
    <perfect-scrollbar
      ref="messages-wrapper"
      :options="{ suppressScrollX: true }"
      :style="{ height: windowHeight - 100 + 'px' }"
      class="infinite-messages-wrapper"
    >
      <template v-if="$permission.$can('message.get', ['user', 'service'])">
        <infinite-loading
          force-use-infinite-wrapper=".infinite-messages-wrapper"
          direction="top"
          :distance="100"
          @infinite="getMoreMessages"
          :identifier="paginations.infiniteId"
        >
          <v-progress-circular indeterminate color="secondary" width="3" size="40" slot="spinner"></v-progress-circular>
          <span slot="no-more"></span>
          <span slot="no-results"></span>
          <div slot="error" slot-scope="{ trigger }">
            <div>{{ $i18n.t("error_occured") }}</div>
            <div>
              <v-btn depressed @click="trigger">
                <span>{{ $i18n.t("btn.retry") }}</span>
              </v-btn>
            </div>
          </div>
        </infinite-loading>
        <message-list :messages="messages" :conversation="conversation"></message-list>
      </template>
    </perfect-scrollbar>
  </v-card-text>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import MessageList from "./message-list";
export default {
  props: {
    paginations: {
      type: Object,
      default: function() {
        return {}
      }
    },
    conversation: {
      type: Object,
      default: function() {
        return {}
      }
    },
    messages: {
      type: Array,
      default: function() {
        return []
      }
    },
    windowHeight: {
      type: Number
    }
  },
  data: () => ({}),
  methods: {
    getMoreMessages($state) {
      this.$emit('getMoreMessages', $state);
    },
    scrollToBottomMessage() {
      const element = this.$refs["messages-wrapper"].$el;
      this.$nextTick(() => {
        element.scrollTo({ top: element.scrollHeight });
      });
    },
  },
  components: {
    InfiniteLoading,
    PerfectScrollbar,
    MessageList
  }
}
</script>