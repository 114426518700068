<template>
  <div>
    <div v-if="!isNotificationType">
      <div v-if="meta.type === 'IMAGE'" class="d-flex align-center">
        <v-icon small class="mr-1">mdi-image-outline</v-icon>
        <span>{{ $i18n.t('chat.messages.type.image') }}</span>
      </div>
      <div v-else-if="meta.type === 'VIDEO'" class="d-flex align-center">
        <v-icon small class="mr-1">mdi-video-outline</v-icon>
        <span>{{ $i18n.t('chat.messages.type.video') }}</span>
      </div>
      <div v-else-if="meta.type === 'FILE'" class="d-flex align-center">
        <v-icon small class="mr-1">mdi-file-document-outline</v-icon>
        <span>{{ $i18n.t('chat.messages.type.file') }}</span>
      </div>
      <div v-else-if="meta.type === 'AUDIO'" class="d-flex align-center">
        <v-icon small class="mr-1">mdi-volume-high</v-icon>
        <span>{{ $i18n.t('chat.messages.type.audio') }}</span>
      </div>
      <div v-html="getContent(content)" v-else></div>
    </div>
    <div v-else>
      <span
        class="grey--text text-body-2 text--darken-1"
      >{{ $i18n.t('chat.messages.notification.' + meta.type, { user: $utils.capitalize(authorId) }) }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    meta: {
      type: Object,
      default: function() {
        return {
          type: "TEXT",
          caption: null
        };
      }
    },
    content: {
      type: String,
      default: ""
    },
    authorId: {
      type: String
    }
  },
  methods: {
    getContent() {
      return this.$sanitize(this.$autolinker.link(this.content));
    }
  },
  computed: {
    isNotificationType() {
      return this.notficationTypes
        ? this.notficationTypes.includes(this.meta.type)
        : false;
    },
    ...mapGetters({ notficationTypes: "message/notificationTypes" })
  }
};
</script>