<template>
  <v-app class="app">
    <app-bar :drawer="drawer" :options="{ 'clipped-left': true, 'clipped-right': true }"></app-bar>
    <conversation
      :drawer="drawer"
      :conversations="conversations"
      :conversation="conversation"
      @selectConversation="selectConversationEvent"
      @getMoreConversations="getMoreConversations"
      :paginations="paginations.conversation"
      :window-height="windowHeight"
      @filterConversationsApply="filterConversationsApply"
    ></conversation>
    <v-main class="pb-0 mb-0">
      <v-container fluid class="px-8 pt-5 pb-0">
        <v-card flat class="messages elevation-1 py-0 mx-auto">
          <v-card-title class="py-0 px-2">
            <v-list two-line class="py-0">
              <v-list-item class="my-0">
                <v-list-item-avatar
                  class="my-0"
                  @click="drawer.left = 1"
                  v-if="!$vuetify.breakpoint.smAndUp"
                >
                  <v-icon>mdi-menu</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <span class="font-weight-medium secondary-font">{{ conversationHeader.title }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ conversationHeader.subtitle }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-spacer></v-spacer>
            <template v-if="conversation">
              <v-menu
                bottom
                left
                v-if="$permission.$can('conversation.open', ['user', 'service']) || $permission.$can('message.get', ['user', 'service'])"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="loading.action"
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    class="mr-1"
                    icon
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <template v-if="$permission.$can('conversation.open', ['user', 'service'])">
                    <template v-if="!isParticipant">
                      <v-list-item @click="joinConversation()" class="px-3">
                        <v-list-item-icon class="mr-0">
                          <v-icon small>mdi-location-enter</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $i18n.t("chat.form.join.title") }}</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                    </template>
                  </template>
                  <v-list-item
                    @click="reloadMessages()"
                    v-if="$permission.$can('message.get', ['user', 'service'])"
                    class="px-3"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon small>mdi-email-sync-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $i18n.t("chat.messages.btn_refresh") }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <template v-if="$permission.$can('ticket.create', ['user', 'service'])">
                <v-menu
                  offset-x
                  :nudge-left="30"
                  :nudge-width="190"
                  :close-on-content-click="false"
                  bottom
                  left
                >
                  <template v-slot:activator="menu">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          target="_blank"
                          v-bind="{ ...attrs, ...menu.attrs }"
                          v-on="{...on, ...menu.on}"
                          icon
                        >
                          <v-icon>mdi-ticket-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $i18n.t("chat.btn.ticket.help") }}</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <template>
                      <v-list-item class="px-3">
                        <v-list-item-content class="d-flex flex-column align-start">
                          <v-select
                            :label="$t('chat.fields.formsId.title')"
                            :placeholder="$t('chat.fields.formsId.placeholder')"
                            v-model="form.formsId"
                            :items="items.forms"
                            item-text="name"
                            item-value="id"
                            :loading="loading.form"
                            style="min-width: 100%;"
                          ></v-select>
                          <v-text-field
                            v-model="form.contactId"
                            :label="$t('chat.fields.contactId.title')"
                            :placeholder="$t('chat.fields.contactId.placeholder')"
                            style="min-width: 100%;"
                          ></v-text-field>
                          <div>
                            <v-btn
                              color="primary"
                              :disabled="!form.formsId"
                              small
                              depressed
                              target="_blank"
                              :to="{ name: 'tickets', query: { form: form.formsId, service: conversation.channel.service.id, channel: conversation.channel.type, contact: form.contactId  } }"
                            >{{ $t('btn.access') }}</v-btn>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn icon v-bind="attrs" v-on="on" @click="drawer.right = !drawer.right">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('chat.conversation.infos.subtitle') }}</span>
              </v-tooltip>
            </template>
          </v-card-title>
          <v-divider></v-divider>
          <div :style="{ height: windowHeight + 'px' }">
            <div v-if="conversation">
              <message
                :conversation="conversation"
                :messages="messages"
                :paginations="paginations.message"
                :window-height="windowHeight"
                @getMoreMessages="getMoreMessages"
                ref="messages"
              ></message>
              <template v-if="$permission.$can('message.send', ['user', 'service'])">
                <v-divider></v-divider>
                <div class="d-flex align-center mt-2">
                  <v-textarea
                    :placeholder="$i18n.t('chat.form.message.placeholder')"
                    filled
                    rounded
                    dense
                    :disabled="!isParticipant"
                    v-model="form.message"
                    hide-details="auto"
                    class="ml-3 mr-2 message-box"
                    no-resize
                    rows="1"
                    auto-grow
                    :max-length="channelLengthLimits.maxLength"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="sendMessage"
                    @keydown.shift.enter.exact="messageNewLine"
                    :class="{
                      'message-input_empty': form.message ? false : true,
                      'message-input_content': form.message ? true : false,
                    }"
                  ></v-textarea>
                  <v-btn
                    color="primary"
                    :disabled="!isParticipant"
                    class="mr-2 depressed"
                    icon
                    @click="sendMessage()"
                  >
                    <v-icon>mdi-send-outline</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex align-center justify-end mr-15" v-if="channelLengthLimits.infos">
                  <span
                    class="text--darken-2 grey--text text-body-2"
                  >{{ channelLengthLimits.infos }}</span>
                </div>
              </template>
            </div>
          </div>
        </v-card>
        <!-- notification -->
        <notification
          :notifications="notifications"
          @openNotification="openNotification"
          @closeNotification="closeNotification"
          @closeAllNotification="closeAllNotification"
        ></notification>
      </v-container>
    </v-main>
    <template v-if="conversation">
      <infos
        :drawer="drawer"
        :contact-info="conversation.contactInfo"
        :window-height="windowHeight"
        :conversation-id="conversation.id"
        :service-id="conversation.channel.service.id"
        @updateContactInfo="updateContactInfo"
        @selectConversation="selectConversationEvent"
        @updateIdentifier="updateIdentifier"
        :identifier="identifier"
      ></infos>
    </template>
  </v-app>
</template>

<style lang="scss">
.conversation {
  overflow-y: hidden;
  .v-navigation-drawer {
    z-index: 1;
  }
  .message-input {
    &_empty {
      .v-input__slot {
        .v-text-field__slot {
          align-items: center;
          textarea {
            min-height: 0 !important;
            margin-top: 6px !important;
            align-self: center;
            line-height: 2.2rem;
            margin-bottom: 6px !important;
          }
        }
      }
    }
    &_content {
      .v-input__slot {
        .v-text-field__slot {
          align-items: center;
          textarea {
            align-self: center;
            line-height: 1.5rem;
            margin-bottom: 6px !important;
            max-height: 38px !important;
            overflow-y: auto !important;
          }
        }
      }
    }
  }
  .messages {
    &.screen {
      margin-left: 300px;
    }
    &-content {
      height: 365px;
    }
    &-list-wrapper {
      height: 300px;
    }
  }
  &-list-wrapper {
    height: 425px;
  }
}
</style>

<script>
import AppBar from "./../../components/layout/app-bar";
import { mapGetters, mapActions } from "vuex";
import Notification from "./notification.vue";
import Conversation from "./conversation";
import Message from "./message";
import Infos from "./infos";
import FilterMixin from "./../../mixins/filter";

const PAGINATION_MESSAGE_LIMIT = 50;
const PAGINATION_CONVERSATION_LIMIT = 10;

export default {
  mixins: [FilterMixin],
  mounted() {
    let self = this;
    this.windowHeight = window.innerHeight - 170;

    this.$nextTick(function() {
      window.addEventListener("resize", function() {
        self.windowHeight = window.innerHeight - 170;
      });
      window.addEventListener("resize", function() {
        self.windowWidth = window.innerWidth;
      });
    });
  },
  data: () => ({
    drawer: {
      left: true,
      right: true
    },
    identifier: null,
    conversations: [],
    conversation: null,
    notifications: [],
    messages: [],
    participants: [],
    items: {
      forms: []
    },
    form: {
      message: null,
      formsId: null,
      contactId: null
    },
    loading: {
      action: false,
      forms: false
    },
    windowHeight: null,
    filters: {
      conversation: []
    },
    paginations: {
      message: {
        page: 0,
        limit: PAGINATION_MESSAGE_LIMIT,
        state: null,
        infiniteId: +new Date()
      },
      conversation: {
        page: 0,
        limit: PAGINATION_CONVERSATION_LIMIT,
        state: null,
        infiniteId: +new Date()
      }
    }
  }),
  sockets: {
    "messages/new": function(data) {
      const { conversation, ...message } = data.message;
      // update conversation
      this.updateConversationById(
        conversation.id,
        {
          ...conversation,
          message: message
        },
        null,
        null,
        true
      );
      // add message
      if (this.conversation && conversation.id === this.conversation.id) {
        this.addMessageToBotom(data.message);
        this.markConversationToRead();
        this.scrollToBottomMessage();
      }
      // notification
      this.notifyNewMessage(data.message);
    },
    // listen last messages grouped by conversation
    "messages/last": function(response) {
      this.loading.conversation = false;
      if (response.status === "success") {
        // set or add conversations
        const messages = response.messages;
        if (messages.page === 1) {
          this.setConversations(messages.data);
        } else {
          this.addConversations(messages.data);
        }
        // inifnnite scroll
        if (this.paginations.conversation.state) {
          if (this.paginations.conversation.page < messages.pageCount) {
            this.$nextTick(() => {
              this.paginations.conversation.state.loaded();
            });
          } else {
            this.paginations.conversation.state.complete();
          }
        }
      } else {
        if (this.paginations.conversation.state) {
          if (response.code === "E403") {
            this.paginations.conversation.state.complete();
          } else {
            this.paginations.conversation.state.error();
          }
        }
      }
    },
    // get list of message by conversation Id
    "messages/list": function(response) {
      if (response.status === "success") {
        const messages = response.messages;
        if (messages.page === 1) {
          this.setMessages(messages.data);
        } else {
          this.addMessages(messages.data.reverse());
        }
        // inifnnite scroll
        if (this.paginations.message.state) {
          if (this.paginations.message.page < messages.pageCount) {
            this.$nextTick(() => {
              this.paginations.message.state.loaded();
            });
          } else {
            this.paginations.message.state.complete();
          }
        }
      } else {
        if (this.paginations.message.state) {
          if (response.code === "E403") {
            this.paginations.message.state.complete();
          } else {
            this.paginations.message.state.error();
          }
        }
      }
    },
    // listen message sent
    "messages/send": function(data) {
      const status = data.status;
      const identifier = data.identifier;

      if (status === "success") {
        const { conversation, ...message } = data.message;
        // update message by identifier
        this.updateMessageById(identifier, data.message);
        // update conversation
        this.updateConversationById(
          conversation.id,
          {
            ...conversation,
            message: message
          },
          null,
          null,
          true
        );
      } else {
        // update message by identifier
        this.updateMessageById(identifier, {
          id: identifier,
          status: "FAILED",
          error: data.description
        });
      }
    },
    // listen message tracking
    "messages/tracking": function(data) {
      const { conversation, ...message } = data.message;
      // update message by identifier
      this.updateMessageById(message.id, data.message);
      // update conversation
      this.updateConversationById(conversation.id, {
        ...conversation,
        message: message
      });
    },
    // receive new conversation
    "conversations/new": function(data) {
      this.addConversationToTop(data.message);
      this.addNotification(data.message);
    },
    // listen get participants
    "conversations/get-participants": function(data) {
      if (data.status === "success") {
        this.participants = data.participants;
      }
    },
    // listen open conversation
    "conversations/open": function(data) {
      this.loading.action = false;
      if (data.status === "success") {
        // add participant
        this.addParticipant(data.participant);
        // add last conversation message
        this.addLastMessageConversation(data.message);
        // add message
        if (this.conversation.id === data.message.conversation.id) {
          this.addMessageToBotom(data.message);
        }
      } else if (data.status === "error") {
        const code = data.code;
        let message;
        if (code === "E403") {
          message = "conversation.errors.open.E101";
        } else {
          message = "error_occured";
        }
        this.notify({ message: this.$i18n.t(message) });
      }
    },
    "conversations/joined": function(data) {
      // add participant
      this.addParticipant(data.participant);
      // add last conversation message
      this.addLastMessageConversation(data.message);
      // add message
      if (this.conversation.id === data.message.conversation.id) {
        this.addMessageToBotom(data.message);
      }
    },
    "conversation/opened": function(data) {
      // add participant
      this.addParticipant(data.participant);
      // add last conversation message
      this.addLastMessageConversation(data.message);
      // add message
      if (this.conversation.id === data.message.conversation.id) {
        this.addMessageToBotom(data.message);
      }
    },
    // listen read conversation
    "conversations/read": function(data) {
      if (data.status === "success") {
        this.updateConversationById(data.conversation.id, data.conversation);
      }
    },
    "conversations/readed": function(data) {
      this.updateConversationById(data.conversation.id, data.conversation);
    },
    // fired on socket reconnection
    authenticated: function() {
      // reload last conversations
      this.getConversations();
      // load new messages
      this.getNewMessagesAfterReconnection();
    }
  },
  methods: {
    getConversations() {
      const qb = this.$requestBuilder.create();
      if (this.filters.conversation.length) {
        for (const filter of this.filters.conversation) {
          qb.setFilter(filter);
        }
      }
      // pagination
      qb.setPage(this.paginations.conversation.page);
      qb.setLimit(this.paginations.conversation.limit);

      this.$socket.emit("messages/last", { query: qb.query() });
    },
    getMoreConversations($state) {
      // update pagination
      this.paginations.conversation.page += 1;
      this.paginations.conversation.state = $state;
      // get messages
      this.getConversations();
    },
    setConversations(datas) {
      const conversations = [];
      for (const data of datas) {
        const { conversation, ...message } = data;
        // change contactInfo to object
        if (conversation.contactInfo === null) {
          conversation.contactInfo = {};
        }
        conversations.push({ ...conversation, message: message });
      }
      this.conversations = conversations;
    },
    selectConversationEvent(conversation) {
      this.form.message = null;
      this.selectConversation(conversation);
    },
    selectConversation(conversation) {
      this.conversation = conversation;
      // reset pagination
      this.paginations.message = {
        page: 0,
        limit: PAGINATION_MESSAGE_LIMIT,
        state: null,
        infiniteId: +new Date()
      };
      // reset message
      this.messages = [];
      // get participants by conversation Id
      this.getParticipants();
      // set contact id
      this.form.contactId = this.$utils.hasProperty(
        this.conversation,
        "contactInfo.identifier"
      )
        ? this.conversation.contactInfo.identifier
        : this.conversation.sourceId;
    },
    addConversations(datas) {
      for (const data of datas) {
        const { conversation, ...message } = data;
        const findIndex = this.conversations.findIndex(
          conv => conv.id === conversation.id
        );
        if (findIndex > -1) {
          // update conversation
          this.updateConversationById(
            data.conversation.id,
            { ...conversation, message: message },
            findIndex
          );
        } else {
          this.addConversationToBottom(data);
        }
      }
    },
    addConversationToTop(data) {
      const { conversation, ...message } = data;
      this.conversations.unshift({ ...conversation, message: message });
    },
    addConversationToBottom(data) {
      const { conversation, ...message } = data;
      this.conversations.push({ ...conversation, message: message });
    },
    filterConversationsApply(form) {
      this.filters.conversation = this.filterApply(form);
      this.loading.conversation = true;
      this.getConversations();
    },
    getMessages() {
      const qb = this.$requestBuilder.create();
      qb.setFilter({
        field: "conversation.id",
        operator: "$eq",
        value: this.conversation.id
      });
      // pagination
      qb.setPage(this.paginations.message.page);
      qb.setLimit(this.paginations.message.limit);

      this.$socket.emit("messages/list", { query: qb.query() });
    },
    reloadMessages() {
      this.paginations.message.page = 1;
      this.getMessages();
    },
    setMessages(messages) {
      this.messages = messages.reverse();
      this.scrollToBottomMessage();
      this.markConversationToRead();
    },
    addMessages(messages) {
      for (const message of messages) {
        const findIndex = this.messages.findIndex(m => m.id === message.id);
        if (findIndex > -1) {
          // update message
          this.updateMessageById(message.id, message, findIndex);
        } else {
          this.addMessageToTop(message);
        }
      }
    },
    getMoreMessages($state) {
      // update pagination
      this.paginations.message.page += 1;
      this.paginations.message.state = $state;
      // get messages
      this.getMessages();
    },
    getNewMessagesAfterReconnection() {
      if (this.conversation) {
        this.paginations.message.page = 1;
        this.getMessages();
      }
    },
    addMessageToTop(message) {
      this.messages.unshift(message);
    },
    addMessageToBotom(message) {
      this.messages.push(message);
    },
    updateMessageById(id, data, index) {
      const findIndex = index || this.messages.findIndex(m => m.id === id);
      if (findIndex > -1) {
        const message = Object.assign(this.messages[findIndex], data, {
          loading: false
        });
        this.messages.splice(findIndex, 1, { ...message });
      }
    },
    notifyNewMessage(message) {
      const userSettings = this.$auth.user().settings;
      if (!this.$utils.getPropertyValue(userSettings, "notification.message")) {
        return;
      }
      const self = this;
      if (message.direction === "OUT") return;

      let notif = null;
      switch (message.meta.type) {
        case "IMAGE":
          notif = self.$i18n.t("chat.messages.type.image");
          break;
        case "VIDEO":
          notif = self.$i18n.t("chat.messages.type.video");
          break;
        case "FILE":
          notif = self.$i18n.t("chat.messages.type.file");
          break;
        case "AUDIO":
          notif = self.$i18n.t("chat.messages.type.audio");
          break;
        default:
          notif = message.content;
          break;
      }
      this.notify({
        message: notif,
        windows: true,
        title: `${this.$utils.getPropertyValue(
          message,
          "conversation.channel.service.name"
        )} | ${this.$utils.getPropertyValue(message, "conversation.sourceId")}`,
        badge:
          process.env.VUE_APP_CHAT_NOTIFICATION_BADGE ||
          "https://app.lmtgroup.com/cdn/omnicanal/logo-128.png",
        icon:
          process.env.VUE_APP_CHAT_NOTIFICATION_ICON ||
          "https://app.lmtgroup.com/cdn/omnicanal/logo-200.png"
      });
    },
    updateConversationById(
      id,
      data,
      index = null,
      path = null,
      moveToTop = false
    ) {
      const findIndex =
        index ||
        this.conversations.findIndex(conversation => conversation.id === id);
      if (findIndex > -1) {
        if (!path) {
          const conversation = Object.assign(
            this.conversations[findIndex],
            data
          );
          this.$set(this.conversations, findIndex, conversation);
        } else {
          this.$utils.setPropertyValue(
            this.conversations[findIndex],
            path,
            data
          );
        }
        if (moveToTop) {
          const toMove = this.conversations.splice(findIndex, 1);
          this.conversations.unshift(...toMove);
        }
      }
    },
    addLastMessageConversation(message) {
      const conversationId = message.conversation.id;
      const findIndex = this.conversations.findIndex(
        conversation => conversation.id === conversationId
      );
      if (findIndex > -1) {
        const conversation = this.conversations[findIndex];
        conversation.message = message;
        this.$set(this.conversations, findIndex, conversation);
      }
    },
    addParticipant(data) {
      this.participants.push(data);
    },
    messageNewLine() {
      // empty
    },
    sendMessage() {
      if (!this.form.message) return;
      if (this.form.message.length > 1600) return;

      const messageId = this.$moment().format("Vx");
      const meta = {
        type: "TEXT",
        caption: null
      };

      this.$socket.emit("messages/send", {
        conversationId: this.conversation.id,
        content: this.form.message,
        meta: meta,
        identifier: messageId
      });

      // add message to conversations and messages
      const message = {
        id: messageId,
        content: this.form.message,
        createdAt: this.$moment().toISOString(),
        conversation: this.conversation,
        direction: "OUT",
        meta: meta,
        loading: true,
        authorId: this.$auth.user().username
      };
      this.addMessageToBotom(message);
      this.addLastMessageConversation(message);
      // scroll to message
      this.scrollToBottomMessage();
      // clear form
      this.form.message = null;
    },
    addNotification(message) {
      const userSettings = this.$auth.user().settings;
      if (
        !this.$utils.getPropertyValue(userSettings, "notification.conversation")
      ) {
        return;
      }
      this.notifications.push({
        id: message.id,
        conversationId: message.conversation.id,
        meta: message.meta,
        content: message.content,
        channel: message.conversation.channel,
        contactId: message.conversation.sourceId
      });
      // message notification
      this.notifyNewMessage(message);
    },
    openNotification(conversationId) {
      const findIndex = this.conversations.findIndex(
        conversation => conversation.id === conversationId
      );
      if (findIndex > -1) {
        this.conversation = this.conversations[findIndex];
      }
      // load messages
      this.selectConversation(this.conversation);
      // close notification
      this.closeNotification(conversationId);
    },
    closeNotification(conversationId) {
      const findIndex = this.notifications.findIndex(
        notification => notification.conversationId === conversationId
      );
      if (findIndex > -1) {
        this.notifications.splice(findIndex, 1);
      }
    },
    closeAllNotification() {
      this.notifications = [];
    },
    joinConversation() {
      if (!this.conversation) return;
      // join conversation
      this.loading.action = true;
      this.$socket.emit("conversations/open", {
        conversationId: this.conversation.id
      });
    },
    closeConversationConfirm() {
      if (!this.conversation) return;
      this.loading.action = true;
      this.$socket.emit("conversations/close", {
        conversationId: this.conversation.id
      });
    },
    scrollToBottomMessage() {
      this.$refs["messages"].scrollToBottomMessage();
    },
    markConversationToRead() {
      if (!this.conversation) return;
      if (!this.conversation.totalMessageUnread) return;
      this.$socket.emit("conversations/read", {
        conversationId: this.conversation.id
      });
    },
    updateContactInfo(data) {
      if (this.conversation && this.conversation.id === data.conversationId) {
        this.$set(this.conversation, "contactInfo", data.contactInfo);
      } else {
        this.updateConversationById(
          data.conversationId,
          data.contactInfo,
          null,
          "contactInfo"
        );
      }
    },
    getParticipants() {
      if (!this.conversation) return;
      this.$socket.emit("conversations/get-participants", {
        conversationId: this.conversation.id
      });
    },
    async setFormsItems(serviceId) {
      this.items.forms = [];
      if (!serviceId) return;
      this.loading.forms = true;
      try {
        this.items.forms = (
          await this.request({
            url: `forms.items?filter=service.id||$eq||${serviceId}`
          })
        ).data;
      } catch (error) {
        // epmpty
      }
      this.loading.forms = false;
      return;
    },
    updateIdentifier(identifier) {
      this.identifier = identifier;
    },
    ...mapActions({
      notify: "notification/notify",
      closeNotify: "notification/close",
      request: "request"
    })
  },
  watch: {
    "$vuetify.breakpoint.xsOnly"(val) {
      if (val) this.drawer.left = true;
    },
    "conversation.channel.service.id"(val) {
      this.setFormsItems(val);
    },
    identifier(val) {
      if (val) {
        this.form.contactId = val;
      } else {
        this.form.contactId = this.conversation
          ? this.conversation.sourceId
          : null;
      }
    }
  },
  components: {
    AppBar,
    Notification,
    Conversation,
    Message,
    Infos
  },
  computed: {
    conversationHeader() {
      if (!this.conversation)
        return {
          title: this.$i18n.t("chat.infos.title"),
          subtitle: this.$i18n.t("chat.infos.subtitle")
        };
      return {
        title: this.$i18n.t("chat.infos.title1", {
          contactId: this.conversation.sourceId,
          channel: this.conversation.channel.type
        }),
        subtitle: this.$i18n.t("chat.infos.subtitle1", {
          service: this.conversation.channel.service.name
        })
      };
    },
    isParticipant() {
      if (this.participants.length < 1) return false;
      const findIndex = this.participants.findIndex(
        participant => participant.participantId === this.$auth.user().username
      );
      return findIndex > -1 ? true : false;
    },
    channelLengthLimits() {
      let channelLengthLimit = {
        maxLength: null,
        maxLengthPart: null,
        infos: ""
      };
      if (!this.conversation) return channelLengthLimit;

      const channelType = this.conversation.channel.type;
      const limits = this._.at(this.channelSettings, `limits.${channelType}`);
      if (!limits.length) return channelLengthLimit;

      const limit = limits[0];
      const messageLength = this.form.message ? this.form.message.length : 0;

      if (limit.max_characters > 0) {
        channelLengthLimit.maxLength = limit.max_characters;
        channelLengthLimit.infos = ` ${this.$utils.pad(
          messageLength,
          2
        )} / ${String(limit.max_characters)}`;
      }
      if (limit.max_characters_part > 0) {
        channelLengthLimit.maxLengthPart = limit.max_characters_part;
        channelLengthLimit.infos = `${this.$utils.pad(
          Math.ceil(messageLength / limit.max_characters_part),
          2
        )} part(s) | ${channelLengthLimit.infos}`;
      }
      return channelLengthLimit;
    },
    ...mapGetters({
      socketIsAuthenticated: "socketIsAuthenticated",
      channelSettings: "settings/channels"
    })
  }
};
</script>